import React from "react";
import ProjectCard from "./ProjectCard";
import { ChildMarkdownRemark, Edge, ProjectFrontmatter } from "../models";

const ProjectGrid: React.FC<ProjectGridProps> = ({ projects }) => {
  return (
    <div className="grid grid-cols-1 grid-rows-1 gap-4 md:grid-cols-2">
      {projects.map((x) => (
        <ProjectCard key={x.node.id} project={x.node} />
      ))}
    </div>
  );
};

ProjectGrid.displayName = "ProjectGrid";

export default ProjectGrid;

export type ProjectGridProps = {
  projects: Edge<ChildMarkdownRemark<ProjectFrontmatter>>[];
};
