import React from "react";

const ProjectCardLink: React.FC<ProjectCardLinkProps> = ({ to, children }) => (
  <a
    className="px-4 py-1 bg-gray-300 transition-colors duration-150 ease-in-out rounded-full shadow-md dark:hover:bg-gray-600 dark:text-gray-300 dark:bg-gray-700"
    href={to}
  >
    {children}
  </a>
);

ProjectCardLink.displayName = "ProjectCardLink";

export default ProjectCardLink;

export type ProjectCardLinkProps = {
  to: string;
  children: React.ReactNode;
};
