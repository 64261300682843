import React from "react";
import { PageProps, useStaticQuery, graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ProjectGrid from "../components/ProjectGrid";
import PageHeader from "../components/PageHeader";
import {
  ChildMarkdownRemark,
  Connection,
  MarkdownFileNode,
  ProjectFrontmatter,
  QueryFragment,
} from "../models";
import MarkdownContent from "../components/MarkdownContent";

type ProjectsPageQueryResult = QueryFragment<
  "allMarkdownRemark",
  Connection<ChildMarkdownRemark<ProjectFrontmatter>>
> &
  QueryFragment<"file", MarkdownFileNode>;

const ProjectsPage: React.FC<PageProps> = () => {
  const {
    allMarkdownRemark: { edges: posts },
    file: {
      childMarkdownRemark: { html: prologue },
    },
  } = useStaticQuery<ProjectsPageQueryResult>(graphql`
    query ProjectsPageQuery {
      allMarkdownRemark(
        filter: {
          frontmatter: { draft: { ne: true } }
          fileAbsolutePath: { regex: "/projects/.*\\\\.md$/" }
        }
        sort: { order: DESC, fields: [frontmatter___date] }
      ) {
        edges {
          node {
            id
            frontmatter {
              type
              title
              subtitle
              tags
              date(formatString: "MMM DD, YYYY")
              demo
              repo
              links {
                name
                url
              }
            }
            html
          }
        }
      }
      file(sourceInstanceName: { eq: "pages" }, name: { eq: "projects" }) {
        childMarkdownRemark {
          html
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="Projects" />
      <PageHeader title="Projects" />
      <MarkdownContent html={prologue} />
      <ProjectGrid projects={posts} />
    </Layout>
  );
};

export default ProjectsPage;
