import React from "react";
import { ChildMarkdownRemark, ProjectFrontmatter } from "../models";
import MarkdownContent from "./MarkdownContent";
import ProjectCardLink from "../components/ProjectCardLink";

const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => (
  <article className="relative flex flex-col p-6 bg-gray-100 border-2 border-gray-100 rounded-md shadow-lg transition-colors duration-300 ease-in-out dark:bg-gray-800 hover:bg-gray-50 dark:border-gray-800 dark:hover:bg-gray-800 dark:hover:border-gray-900">
    <div className="absolute py-1 pl-3 pr-2 top-4 right-0 bg-gray-50 text-gray-600 text-xs uppercase rounded-l-lg select-none dark:bg-gray-600 dark:text-gray-50">
      {project.frontmatter.type}
    </div>
    <h2 className="text-gray-900 text-xl dark:text-gray-300">
      {project.frontmatter.title}
    </h2>
    <MarkdownContent html={project.html} />
    <div className="-mx-0.5 mt-auto flex space-x-2">
      {project.frontmatter.links?.map(({ name, url }) => (
        <ProjectCardLink to={url}>{name}</ProjectCardLink>
      ))}
    </div>
  </article>
);

ProjectCard.displayName = "ProjectCard";

export default ProjectCard;

export type ProjectCardProps = {
  project: ChildMarkdownRemark<ProjectFrontmatter>;
};
